/*
  File: Assignment No. 2
  Student’s Name: John Raineir Po-on
  StudentID: 301363442
  Date: 01/21/2024
*/

#projects {
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;

}

.projTitle {
    font-size: 3rem;
    font-weight: 600;
    padding-top: 3rem;
}

.projDesc {
    font-weight: 400;
    font-size: 1rem;
    max-width: 51rem;
    padding: 0 2rem;

}

.projBars {
    margin: 0.5;
    width: 100vw;
    max-width: 80%;
    text-align: left;

}

.projBar {
    display: flex;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    background: transparent;
}

.pdfIcon {

    object-fit: cover;
    height: 1.7rem;
    width: 1.7rem;
    margin-left: 0.5rem;
    margin-top: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
}


.projBarImg {
    object-fit: cover;
    height: 10rem;
    width: 10rem;
    margin-right: 2rem;
    margin-top: 2rem;
    border-radius: 1rem;

}

.projBarText {
    font-size: medium;
    font-weight: 200;
    color: #c0b092;
}


.projBarText>p {
    font-size: 0.8rem;
    font-weight: 200;
    color: #f3f3e9;
}


@media screen and (max-width: 720px) {
    .projBarText>p {
        font-size: 2vw;
    }
}

@media screen and (max-width: 480px) {
    .projBarText>p {
        font-size: 3vw;
    }
    .sprojBarImg {
        height: 2.2rem;
        width: 2.5rem;
    }
    .projBarText>h2 {
        font-size: 5vw;
    }
}