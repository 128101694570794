/*
  File: Assignment No. 2
  Student’s Name: John Raineir Po-on
  StudentID: 301363442
  Date: 01/21/2024
*/

#contactPage {
    min-height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 60rem;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.contactPageTitle {
    font-size: 3rem;
    margin-bottom: 1rem;
}


.servicesDesc {
    color: white;
    font-weight: 300;
    font-size: 1rem; 

}



/* services font */
.servicesImgs {
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;

}

/* services image */
.servicesImg {
    object-fit: cover;
    width: 23%;
    min-width: 9rem;
    margin: auto;
    padding: 0.25rem;
}

.mobileImg {
    object-fit: fill;
    height: 10rem;
    width: 23%;
    min-width: 9rem;
    margin: auto;
    padding: 0rem;
}

#services {
    padding: 5rem;
}

.link {
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin: 0.5rem;
    padding: 0.2rem;
}


.contactDesc {
    padding: 1rem;
    font-size: medium;
    font-weight: 200;
    color: rgb(207, 205, 205);
}



.contactForm {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;
}

.name, .email, .msg {
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    color: #E5E5CB;
    border: none;
    border-radius: 0.5rem;
    background-color: #28211e;
}

.submitBtn {
    background-color: #E5E5CB;
    font-size: small;
    color: #3C2A21;
    border: none;
    border-radius: 0.5rem;
    margin: 1rem;
    padding: 0.75rem 2rem;
    cursor: pointer;
    
}


.submitBtn:hover {
    background: #ffffff;
}
