/*
  File: Assignment No. 2
  Student’s Name: John Raineir Po-on
  StudentID: 301363442
  Date: 01/21/2024
*/

/* Space for content middle */
#intro {
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 75rem;
    margin: auto;
    overflow: hidden;
    position: relative;
    
}


/* MOTOR */
.bg {
    position: absolute;
    top: 2.6rem;
    right: 0;
    z-index: -1;
    object-fit: cover;
    height: 83vh;
}

/* paraghrap Font */
.introContent {
    height: 100vh;
    width: 100vh;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.hello {
    font-size: 1.75rem;
    font-weight: 100;

}

.introName {
    color: rgb(197, 194, 194);
}

.introPara {
    font-size: medium;
    font-weight: 150;
    letter-spacing: 1px;
    color: rgb(205, 205, 205);
    
}

/*BAG */
.btn {
    background: #E5E5CB;
    color: #3C2A21;
    margin: 1rem 0;
    padding: 0.75rem 1.5rem; 
    border-radius: 2rem;
}

.btnImg {
    object-fit: cover;
    margin: 0 0.25rem;
    height: 1rem;
}

@media screen and (max-width:840px){
    .bg {
        right: -10vw;
    }
    .introContent {
        font-size: 4vw;
    }
    .hello {
        font-size: 4vw;
    }
    .introPara {
        font-size: 2vw;
    } 

}

/*
@media screen and (max-width:480px){
    .bg { display: none;


    }
}
*/