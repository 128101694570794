/*
  File: Assignment No. 2
  Student’s Name: John Raineir Po-on
  StudentID: 301363442
  Date: 01/21/2024
*/

#skills {
    overflow: hidden;
    width: 100vw;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;

}

.skillTitle {
    font-size: 3rem;
    font-weight: 600;
    padding-top: 3rem;
    margin-bottom: 0.5rem;
}

.skillDesc {
    font-weight: 400;
    font-size: 1rem;
    max-width: 51rem;
    padding: 0 2rem;

}

.skillBars {
    margin: 0.5;
    width: 100vw;
    max-width: 80%;
    text-align: left;

}

.skillBar {
    display: flex;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    background: #28211e;
}

.skillBarImg {
    object-fit: cover;
    height: 7rem;
    width: 6rem;
    margin-right: 2rem;

}

.skillBarText {
    font-size: 0.8rem;
    font-weight: 200;
    color: #c0b092;
}


.skillBarText>p {
    font-size: 0.8rem;
    font-weight: 200;
    color: #f3f3e9;
}


@media screen and (max-width: 720px) {
    .skillBarText>p {
        font-size: 2vw;
    }
}

@media screen and (max-width: 480px) {
    .skillBarText>p {
        font-size: 3vw;
    }
    .skillBarImg {
        height: 2.2rem;
        width: 2.5rem;
    }
    .skillBarText>h2 {
        font-size: 5vw;
    }
}